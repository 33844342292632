<template>
  <div class="container">
    <div class="text-center my-5">
      <h3>{{ question.content }}</h3>
    </div>
    <div class="justify-center row">
        <YWCAOption 
          class="mx-5 w-20% col-sm-12 col-md-6 col-lg-3"
          v-for="option in props.question.options" 
          :key="option.value" 
          :option="option" 
          :name="question.content"
          @change="handleChange" />
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { onMounted, ref, computed } from 'vue'
  import { type Option, type Question } from '../../types/YMCA/Question'
  import YWCAOption from './YWCAOption.vue'

  const props = defineProps<{
    question: Question
  }>()

  const emit = defineEmits<{
    (e: 'on-next-question', value: number): void
  }>()

  function handleChange(option: Option) {
    emit('on-next-question', option.value)
  }
</script>
<style scoped lang="scss">
</style>