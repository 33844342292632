<template>
  <div class="justify-center">
    <div>
        <YWCABanner />
        <div class="text-center">
            <h1>Bilan de relation</h1>
            <p v-if="showQuestions">{{ currentQuestionIndex }} / {{ questions.length }}</p>
        </div>
        <div class="flex-wrap container min-h-700px">
            <button @click="handlePreviousQuestion" class="btn btn-primary w-150px h-75px">Précédent</button>
            <YWCAQuestion 
                class="border-rd-8px w-100% justify-center border-solid border-gray-300 p-4 my-4 min-h-400px" 
                v-if="showQuestions && questions[currentQuestionIndex]"
                :question="questions[currentQuestionIndex]"
                @on-next-question="handleNextQuestion"/>
            <YWCADiagnostic
                class="border-rd-8px w-100% justify-center border-solid border-gray-300 p-4 my-4"
                v-else
                :violence="score > 0"/>
        </div>
        <button @click="redirectTowardLink" class="btn-primary border-rd-8px fixed bottom-0 right-0 p-2 m-2 z-1">Sortie Rapide -></button>
    </div>
    <YWCAFooter />
  </div>
</template>
<script lang="ts" setup>
    import { onMounted, ref, computed, type Ref } from 'vue'
    import { type Option, type Question } from '../types/YMCA/Question'
    import { type RedirectLink } from '../types/YMCA/RedirectLink'
    import questionsData from '../assets/contents/questions_fr.json'
    import redirectLinksData from '../assets/contents/redirect_to_links.json'
    import YWCAQuestion from '../components/ywca/YWCAQuestion.vue'
    import YWCABanner from '../components/ywca/YWCABanner.vue'
    import YWCAFooter from '../components/ywca/YWCAFooter.vue'
    import YWCADiagnostic from '../components/ywca/YWCADiagnostic.vue'

    const currentQuestionIndex: Ref<number> = ref(0)
    const score: Ref<number> = ref(0)
    const questions: Ref<Question[]> = ref(questionsData.questions.map((json: any) => serializeJsonToQuestion(json)))
    const redirectLinks: Ref<RedirectLink[]> = ref(redirectLinksData.links.map((json: any) => serializeJsonToRedirectLink(json)))
    const showQuestions = computed(() => currentQuestionIndex.value < questions.value.length)
    const randomLink = computed(() => {
        const randomIndex = Math.floor(Math.random() * redirectLinks.value.length)
        return redirectLinks.value[randomIndex]
    })

    function serializeJsonToQuestion(json: any): Question {
        return {
            content: json.content,
            options: json.options.map((option: any): Option => ({
                content: option.content,
                value: option.value,
            }))
        }
    }

    function serializeJsonToRedirectLink(json: any): RedirectLink {
        return {
            id: json.id,
            url: json.url,
        }
    }

    function redirectTowardLink() {
        window.location.href = randomLink.value.url
    }

    function handleNextQuestion(optionValue: number) {
        console.log('allo')
        score.value += optionValue
        if(!questions.value[currentQuestionIndex.value]) return
        
        if(showQuestions.value) currentQuestionIndex.value++
    }

    function handlePreviousQuestion() {
        if(!questions.value[currentQuestionIndex.value]) return

        if(currentQuestionIndex.value > 0) currentQuestionIndex.value--
    }
</script>
<style lang="scss">
    // html, body {
    //     margin: 0;
    //     padding: 0;
    // }

    // .base {
    //     min-height: 100vh; // Valeur par défaut
    // }

    // @media (max-width: 640px) { // Mobile
    //     .base {
    //         min-height: 400px; 
    //     }
    // }

    // @media (min-width: 641px) and (max-width: 1024px) { // Tablette
    //     .base {
    //         min-height: 600px; 
    //     }
    // }

    // @media (min-width: 1025px) { // Bureau
    //     .base {
    //         min-height: 800px; 
    //     }
    // }
</style>
