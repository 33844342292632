<template>
  <div>
    <div class="text-center">
      <h3>{{ props.violence ? 'Violence' : 'Pas de violence' }}</h3>
    </div>
    <div v-if="violence">
      Les signes relevés indiquent que vous pourriez vivre des situations de violence conjugale. Sachez que ce n’est pas de votre faute, et vous méritez de vivre dans un environnement sûr, respectueux et sans violence.
      Parler de ce que vous vivez peut être difficile, mais sachez que des ressources et des personnes bienveillantes sont disponibles pour vous écouter, vous accompagner et vous soutenir. Que vous soyez prêt·e ou non à agir maintenant, vous pouvez explorer ces ressources à votre rythme. Pour obtenir de l’aide, vous pouvez contacter SOS Violence conjugale en tout temps au 1 800-363-9010.
    </div>
    <div v-else>
      D’après vos réponses, aucun signe de violence conjugale n’a été relevé dans votre relation. Il est tout à fait normal de s’interroger sur la santé de ses relations, et il est important de rester attentif·ve à votre bien-être et à celui de vos proches. Cependant, si vous avez des doutes, n’hésitez par à consultez les ressources suivantes : 
      SOS violence conjugale 24h/7
      Vous souhaitez parler à quelqu’un rapidement ou vous souhaitez trouver un hébergement peu importe l’endroit au Québec, une intervenante vous référera à la ressources dont vous avez besoin. De plus, vous souhaitez avoir plus d’information sur la violence conjugale, SOS Violence conjugale fournit plusieurs outils de sensibilisation.

      Texto : 438-601-1211
      Téléphone : 1 800-363-9010

      Ligne de soutien Info-Social 811
      Ce service téléphonique permet de parler à un intervenant en cas de besoin de soutien psychologique ou social.
      Si vous avez des inquiétudes futures ou si une personne de votre entourage semble vivre des situations difficiles, n’hésitez pas à consulter des ressources d’aide ou à en parler avec des personnes de confiance.

    </div>
  </div>
</template>
<script lang="ts" setup>

const props = defineProps<{
  violence: boolean
}>()
</script>
<style scoped lang="scss">
</style>