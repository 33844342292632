<template>
  <div 
    class="border-cyan border-solid border-1 p-5 border-rd-8px cursor-pointer text-center hover:bg-gray-200"
    @click="handleChange">
    <label>
      {{ props.option.content }}
    </label>
  </div>
</template>

<script lang="ts" setup>
  import { type Option } from '../../types/YMCA/Question'

  const emit = defineEmits<{
    (e: 'change', option: Option): void
  }>()

  const props = defineProps<{
    option: Option
    name: string
  }>()

  function handleChange() {
    emit('change', props.option)
  }
</script>

<style scoped lang="scss">
</style>
